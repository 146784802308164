<template>
  <div id="booking">
    <v-toolbar dark color="blue darken-2">
      <v-toolbar-title class="white--text">{{ $t('reserva.titulo') }}</v-toolbar-title>
      <v-spacer />
      <v-btn icon to="login">
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container grid-list-xl fluid>
      <v-layout wrap>
        <v-flex lg12 md12>
          <v-card class="mb-4">
            <v-toolbar color="blue" dark flat dense cad>
              <v-toolbar-title class="subheading font_blanco">{{ $t('reserva.reunirse_roberto') }}</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-divider />
            <v-card-text v-if="!successReserva" class="text-center color_blanco">
              <v-layout wrap>
                <v-flex xs12>
                  <h3>{{ $t('reserva.seleccione_fecha_hora') }}:</h3>
                </v-flex>
                <v-flex xs12 sm8>
                  <v-date-picker
                    v-model="picker"
                    :min="new Date().toISOString().substr(0, 10)"
                    locale="es"
                    first-day-of-week="1"
                  />
                </v-flex>
                <v-flex xs12 sm4>
                  <div style="overflow: scroll; height: 380px; overflow-x: hidden;">
                    <div v-for="hora in horas" :key="hora" class="xs12">
                      <v-chip label outlined color="blue" @click="seleccionaHora(hora)"
                        ><span style="width: 100px">{{ hora }}</span></v-chip
                      >
                    </div>
                  </div>
                </v-flex>
                <v-flex v-if="horaSeleccion !== ''">
                  <h3>{{ this.$t('reserva.se_reservara_a_las', { dia: diaSeleccion, hora: horaSeleccion }) }}</h3>
                </v-flex>
                <v-flex class="text-center text-sm-right">
                  <v-btn :color="!btn_inactivo ? 'success' : 'blue-grey lighten-4'" dark @click="reservar">{{
                    $t('reserva.boton')
                  }}</v-btn>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-text v-else class="text-center">
              <v-layout wrap>
                <v-flex xs12>
                  <img
                    style="max-width: 150px"
                    width="40%"
                    alt=""
                    class="private-image private-image--object-fit-contain private-illustration"
                    src="/static/success.svg"
                  />
                  <h3>{{ $t('reserva.reserva_confirmada') }}</h3>
                </v-flex>
                <v-flex xs12>
                  <p>
                    {{ $t('reserva.ha_hecho_una_reserva') }}<br />
                    {{ $t('reserva.recibira_invitacion_por_correo') }}
                  </p>
                  <h4>
                    {{ diaSeleccion }}<br />
                    {{ horaSeleccion }}
                  </h4>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-snackbar
        v-model="snackbar.show"
        :timeout="snackbar.time"
        top
        right
        :multi-line="snackbar.mode === 'multi-line'"
        :color="snackbar.color"
      >
        {{ snackbar.text }}
        <v-btn dark text icon @click.native="snackbar.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-snackbar>
    </v-container>
    <v-footer height="auto">
      <v-card text tile class="blue darken-4 white--text text-center" style="width: 100%">
        <v-card-text>
          <v-btn icon class="mx-3 white--text" @click="abrirDireccion('https://www.facebook.com/Docline.es/')">
            <v-icon size="24px">fa-facebook</v-icon>
          </v-btn>
          <v-btn icon class="mx-3 white--text" @click="abrirDireccion('https://twitter.com/Docline_')">
            <v-icon size="24px">fa-twitter</v-icon>
          </v-btn>
          <v-btn
            icon
            class="mx-3 white--text"
            @click="abrirDireccion('https://www.youtube.com/channel/UCykR-w1g1eN-h_eoSOgxZww')"
          >
            <v-icon size="24px">fa-youtube</v-icon>
          </v-btn>
          <v-btn
            icon
            class="mx-3 white--text"
            @click="abrirDireccion('https://www.linkedin.com/company-beta/10984777/')"
          >
            <v-icon size="24px">fa-linkedin</v-icon>
          </v-btn>
        </v-card-text>
        <v-card-text class="white--text"> &copy;{{ anio }} — <strong>Docline</strong> </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
import { makeBookingUrl } from '../config/config';

export default {
  components: {},
  data: () => ({
    picker: new Date().toISOString().substr(0, 10),
    horaSeleccion: '',
    diaSeleccion: '',
    anio: '2019',
    successReserva: false,
    btn_inactivo: true,
    snackbar: {
      show: false,
      text: '',
      color: '',
      time: 3000,
      mode: '',
    },
    horas: [],
  }),

  computed: {},

  created() {
    const param = {
      key: this.$route.params.key,
      accion: 'comprobarkey',
    };
    this.$http.post(makeBookingUrl, param).then(response => {
      if (response.status === 200 && response.data.estado === 1) {
        const intervalo = setInterval(() => {
          this.itereaciones_itercom = this.itereaciones_itercom + 1;
          const intercom = document.getElementsByClassName('intercom-launcher')[0];
          if (typeof intercom !== 'undefined' && intercom !== null) {
            intercom.style.display = 'none';
            clearInterval(intervalo);
          }
          if (this.itereaciones_itercom > 20 || (intercom && intercom.style.display === 'none')) {
            clearInterval(intervalo);
          }
        }, 1500);
        this.horas = response.data.horas;
      } else {
        this.$router.push('/Login');
      }
    });
  },
  methods: {
    reservar() {
      if (!this.btn_inactivo) {
        this.btn_inactivo = true;
        const param = {
          key: this.$route.params.key,
          fecha: this.picker,
          hora: this.horaSeleccion,
          accion: 'reserva',
        };
        this.$http.post(makeBookingUrl, param).then(response => {
          if (response.status === 200 && response.data.estado === 1) {
            this.snackbar.color = 'green';
            this.snackbar.text = this.$t('success.save_common');
            this.snackbar.show = true;
            setInterval(() => {
              this.$router.push('/Login');
            }, 2500);
          } else {
            this.snackbar.color = 'red';
            this.snackbar.text = this.$t('errors.try_again');
            this.snackbar.show = true;
          }
        });
      }
    },
    seleccionaHora(hora) {
      const fec = this.picker.split('-');
      this.diaSeleccion = fec[2] + '/' + fec[1] + '/' + fec[0];
      this.horaSeleccion = hora;
      this.btn_inactivo = false;
    },
  },
};
</script>
<style lang="css">
.btn_home {
  text-transform: uppercase;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 22px !important;
  letter-spacing: normal !important;
  font-family: 'Roboto', sans-serif !important;
  margin-top: -5px;
  margin-bottom: -5px;
}
.fecha_prox_consultas {
  margin-right: 5px;
  margin-bottom: 12px;
}

time.icon {
  font-size: 1em; /* change icon size */
  display: block;
  position: relative;
  width: 4em;
  height: 4em;
  background-color: #fff;
  border-radius: 0.6em;
  box-shadow: 0 1px 0 #bdbdbd, 0 2px 0 #fff, 0 3px 0 #bdbdbd, 0 4px 0 #fff, 0 5px 0 #bdbdbd, 0 0 0 1px #bdbdbd;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: rotate(0deg) skewY(0deg);
  -webkit-transform-origin: 50% 10%;
  transform-origin: 50% 10%;
}

time.icon * {
  display: block;
  width: 100%;
  font-size: 1em;
  font-weight: bold;
  font-style: normal;
  line-height: 1.2em;
  text-align: center;
}

time.icon strong {
  position: absolute;
  top: 0;
  padding: 0;
  color: #fff;
  background-color: #007cd1;
  border-bottom: 1px dashed #3a59b4;
  box-shadow: 0 2px 0 #3090d8;
}

time.icon em {
  position: absolute;
  bottom: 0.3em;
  color: #3090d8;
}

time.icon span {
  width: 100%;
  font-size: 2.5em;
  letter-spacing: -0.05em;
  padding-top: 0.45em;
  color: #2f2f2f;
}
.prox_consultas_nombre {
  cursor: pointer;
}
.prox_consultas_nombre:hover {
  color: #0a2953;
}
.contenido_prox_consultas {
  margin-top: -10px;
}
.icon_recibido {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
  color: #ff9800 !important;
}
.icon_enviado {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  color: #4caf50 !important;
}

.fecha_ult_mensajes {
  color: #51565f;
  font-size: 13px;
}

.md-card-profile {
  width: 96%;
  margin: -50px auto 0;
  overflow: hidden;
  -webkit-box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.avatar {
  z-index: 1;
  -webkit-box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.form_marketplace {
  margin-top: 40px;
}
.foto_usuario:hover {
  box-shadow: 0 10px 30px -12px #2196f369, 0 4px 25px 0 #2196f370, 0 8px 10px -5px rgba(33, 150, 243, 0.3);
}

.p-x-1 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.time-picker .time-picker-btn {
  padding: 12px 24px;
  max-width: 100%;
  color: #00a4bd;
  border-color: #cbd6e2;
}

.meetings-lib .duration-options,
.meetings-lib .time-picker-btn,
.meetings-lib .week-day-name {
  font-size: 0.75rem;
}

.private-selectable-box {
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
  border: 1px solid #7fd1de;
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: auto;
}

#booking {
  background-repeat: repeat;
  background-image: url('/static/faq_bg.png');
}

.color_blanco {
  background: #fff;
}

.font_blanco {
  color: #fff;
}
</style>
